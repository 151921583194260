export enum PaymentMethod {
	PAYPAL = 'paypal',
	CASH = 'cash',
	CREDIT_CARD = 'credit_card',
	VISA = 'visa',
	MASTERCARD = 'mastercard',
	SEPA = 'sepa',
	BALANCE = 'balance',
	POS = 'pos',
	SOFORTBANKING = 'sofortbanking',
	GPAY = 'google_pay',
	APAY = 'apple_pay',
	WALLEE = 'wallee',
	POSTFINANCE_CARD = 'postfinance_card',
	POSTFINANCE = 'postfinance',
	REKA = 'reka',
	TWINT = 'twint',
	LUNCHECK = 'luncheck',
	BONCARD = 'boncard',
	CARD_WORLDLINE = 'worldline'
}
